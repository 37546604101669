import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
        <div className="board">
    <h1>Hello World</h1>
  </div>
    </div>
  );
}

export default App;
